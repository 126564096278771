import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import images from '~/utils/ImageStore';
import TextField from '../TextField/TextField';
import withTranslate from '../../shared/withTranslate/withTranslate';
import './style.sass';

export class PasswordField extends Component {
  constructor() {
    super();
    this.valueChange = this.valueChange.bind(this);
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
    this.toggleHide = this.toggleHide.bind(this);
    this.state = {
      hidden: true,
      focused: false,
      touched: false,
    };
  }

  static onCopyCut(event) {
    event.preventDefault();
  }

  valueChange(value) {
    this.props.onChange(value);
    this.touch();
  }

  toggleHide() {
    this.setState({ hidden: !this.state.hidden });
    this.textField.focus();
  }

  focus() {
    this.setState({ focused: true });
  }

  blur() {
    this.setState({ focused: false });
  }

  imageUrl() {
    if (this.state.hidden && this.state.focused) {
      return images.showPasswordSelected;
    } else if (this.state.hidden && !this.state.focused) {
      return images.showPasswordNotSelected;
    } else if (!this.state.hidden && this.state.focused) {
      return images.hidePasswordSelected;
    } else if (!this.state.hidden && !this.state.focused) {
      return images.hidePasswordNotSelected;
    }
    return images.showPasswordNotSelected;
  }

  classes() {
    return classnames({
      'PasswordField-field': true,
      focused: this.state.focused,
      error: this.showError(),
    });
  }

  showError() {
    return !this.props.isValid(this.props.value) && this.state.touched;
  }

  touch() {
    this.setState({ touched: true });
  }

  renderField() {
    return (
      <div className={this.classes()}>
        <TextField
          caption={this.props.t('passwordRequirements')}
          isPassword={this.state.hidden}
          label={this.props.t('password')}
          onBlur={this.blur}
          onChange={this.valueChange}
          onKeyPress={this.props.onKeyPress}
          onFocus={this.focus}
          onCopy={PasswordField.onCopyCut}
          onCut={PasswordField.onCopyCut}
          value={this.props.value}
          ref={(el) => { this.textField = el; }}
        >
          <button onClick={this.toggleHide} type="button">
            <img alt="show/hide password" src={this.imageUrl()} />
          </button>
        </TextField>
      </div>
    );
  }

  render() {
    return (
      <div className="PasswordField">
        { this.renderField() }
      </div>
    );
  }
}

PasswordField.propTypes = {
  isValid: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  value: PropTypes.string,
  t: PropTypes.func.isRequired,
};

PasswordField.defaultProps = {
  value: undefined,
};

export default withTranslate(PasswordField);
