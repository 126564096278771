import _ from 'lodash';
import { emailActivate, codeActivate, register, minorActivate, captureParentalConsent } from '~/services/userService';
import { codesByCountry, countryToIso2, getMajorityAge } from '~/utils/supportedCountries';
import { DEFAULT_COUNTRY_CODE } from '~/utils/constants/countryCodes';
import { extractResponseError } from '~/utils/errors';
import { logError } from '~/services/loggingService';
import { C4CNovo } from '~/utils/constants/subscriptionModels';
import { getPartner, getMajorityAge as getMajorityAgeFromUrl } from '~/utils/navigation';
import { isOfAge } from '~/utils/dateUtils';
import { fetchDiabetesDevices } from '~/store/modules/deviceSelection';
import {
  onProceedToDeviceSelection,
  onProceedToTherapy,
  onProceedToUploader,
  onProceedToInsuletWeb,
} from './enrollmentWizard';
import { onSessionReceived } from './session';
// ACTIONS
export const UPDATE_ATTRIBUTE = 'accountInformation/UPDATE_ATTRIBUTE';
export const UPDATE_COUNTRY = 'accountInformation/UPDATE_COUNTRY';
export const ON_REGISTER = 'accountInformation/ON_REGISTER';
export const REGISTER_REQUESTED = 'accountInformation/REGISTER_REQUESTED';
export const REGISTER_RECEIVED = 'accountInformation/REGISTER_RECEIVED';
export const UPDATE_CODE = 'accountInformation/UPDATE_CODE';
export const STARTED_ENROLLMENT = 'logTrackingEvent/STARTED_ENROLLMENT';
export const COMPLETED_ENROLLMENT = 'logTrackingEvent/COMPLETED_ENROLLMENT';
export const PARENTAL_CONSENT_REQUESTED = 'accountInformation/PARENTAL_CONSENT_REQUESTED';
export const PARENTAL_CONSENT_RECEIVED = 'accountInformation/PARENTAL_CONSENT_RECEIVED';


const initialState = {
  code: undefined,
  consents: {},
  countryCode: DEFAULT_COUNTRY_CODE,
  phoneCountry: 'us',
  registerStatus: undefined,
  registerError: undefined,
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ATTRIBUTE:
      return {
        ...state,
        [action.attribute]: action.value,
      };

    case UPDATE_COUNTRY:
      return {
        ...state,
        countryOfResidence: action.country,
        countryCode: codesByCountry[action.country],
        phoneCountry: countryToIso2[action.country],
      };

    case REGISTER_REQUESTED:
      return {
        ...state,
        registerStatus: 'pending',
      };

    case REGISTER_RECEIVED:
      return {
        ...state,
        registerStatus: action.registerStatus,
        registerError: action.registerError,
      };

    case UPDATE_CODE:
      return {
        ...state,
        code: action.code,
      };

    default:
      return state;
  }
};

// ACTION CREATORS
export const onUpdateAttribute = update => (
  { type: UPDATE_ATTRIBUTE, attribute: update.attribute, value: update.value }
);

export const onUpdateCountry = country => (
  { type: UPDATE_COUNTRY, country }
);

export const updateCode = code => (
  { type: UPDATE_CODE, code }
);

const getNextStep = (user) => {
  if (getPartner() === 'op5') {
    return onProceedToInsuletWeb;
  }
  if (user && user.glookoResearchSite) {
    return onProceedToUploader;
  } else if (user && user.primaryAuthorizedSite && user.primaryAuthorizedSite.subscriptionModel
    && user.primaryAuthorizedSite.subscriptionModel.features.find(feature => feature === C4CNovo)) {
    return onProceedToTherapy;
  }
  return onProceedToDeviceSelection;
};

export const onRegister = () =>
  (dispatch, getState) => {
    const userFieldList = [
      'email', 'password', 'firstName', 'lastName', 'dateOfBirth',
      'countryOfResidence', 'language', 'meterUnits',
      'code', 'consents', 'guardianInfo', 'receiveSms',
    ];
    const accountInfo = getState().accountInformation;
    const fullMobilePhone = accountInfo.mobilePhone ?
      { mobilePhone: `${accountInfo.countryCode}${accountInfo.mobilePhone}` } : {};
    const majorityAge = getMajorityAge(
      accountInfo.countryOfResidence,
      getState().countries.allCountries,
    );
    const isMinor = !isOfAge(accountInfo.dateOfBirth, majorityAge);

    dispatch({ type: REGISTER_REQUESTED });

    return register(
      Object.assign({}, _.pick(accountInfo, userFieldList), fullMobilePhone),
      getState().language.language,
      isMinor,
    )
      .then((res) => {
        dispatch({
          type: REGISTER_RECEIVED,
          registerStatus: 'registered',
          registerError: undefined,
        });
        dispatch(onSessionReceived('valid', res.data.user));
        dispatch(fetchDiabetesDevices());
        dispatch(getNextStep(res.data.user)());
        dispatch({ type: STARTED_ENROLLMENT, enrollmentType: 'registration' });
      })
      .catch((error) => {
        dispatch({
          type: REGISTER_RECEIVED,
          registerStatus: 'failed',
          registerError: extractResponseError(error),
        });
        logError(error);
      });
  };

export const onEmailActivate = () =>
  (dispatch, getState) => {
    const userFieldList = [
      'password', 'consents',
      'dateOfBirth', 'tempToken',
      'professional', 'guardianInfo',
    ];
    const { dateOfBirth, professional } = getState().accountInformation;
    const payload = Object.assign(
      _.pick(getState().accountInformation, userFieldList),
      getState().language,
    );
    const isMinor = !professional && !isOfAge(dateOfBirth, getMajorityAgeFromUrl());

    dispatch({ type: REGISTER_REQUESTED });
    return emailActivate(payload, isMinor)
      .then((res) => {
        dispatch({
          type: REGISTER_RECEIVED,
          registerStatus: 'registered',
          registerError: undefined,
        });
        dispatch(onSessionReceived('valid', res.data.user));
        if (professional) {
          window.location.href = MY_GLOOKO_URL; // eslint-disable-line no-undef
        } else {
          dispatch(fetchDiabetesDevices());
          dispatch(getNextStep(res.data.user)());
        }
        dispatch({ type: STARTED_ENROLLMENT, enrollmentType: 'email_activation' });
      })
      .catch((error) => {
        dispatch({
          type: REGISTER_RECEIVED,
          registerStatus: 'failed',
          registerError: extractResponseError(error),
        });
        logError(error);
      });
  };

export const onCodeActivate = () =>
  (dispatch, getState) => {
    const userFieldList = [
      'email', 'password', 'consents',
      'dateOfBirth', 'code', 'guardianInfo',
    ];
    dispatch({ type: REGISTER_REQUESTED });
    return codeActivate(_.pick(getState().accountInformation, userFieldList))
      .then((res) => {
        dispatch({
          type: REGISTER_RECEIVED,
          registerStatus: 'registered',
          registerError: undefined,
        });
        dispatch(onSessionReceived('valid', res.data.user));
        dispatch(fetchDiabetesDevices());
        dispatch(getNextStep(res.data.user)());
        dispatch({ type: STARTED_ENROLLMENT, enrollmentType: 'code_activation' });
      })
      .catch((error) => {
        dispatch({
          type: REGISTER_RECEIVED,
          registerStatus: 'failed',
          registerError: error.response.data,
        });
        logError(error);
      });
  };

export const onMinorActivate = payload =>
  (dispatch) => {
    dispatch({ type: REGISTER_REQUESTED });
    return minorActivate(payload)
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: REGISTER_RECEIVED,
            registerStatus: 'registered',
            registerError: undefined,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: REGISTER_RECEIVED,
          registerStatus: 'failed',
          registerError: error.response.data,
        });
        logError(error);
      });
  };

export const onMinorParentalConsent = payload =>
  (dispatch) => {
    dispatch({ type: REGISTER_REQUESTED });
    return captureParentalConsent(payload)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: REGISTER_RECEIVED,
            registerStatus: 'registered',
            registerError: undefined,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: REGISTER_RECEIVED,
          registerStatus: 'failed',
          registerError: error.response.data,
        });
        logError(error);
      });
  };
