import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import momentPropTypes from 'react-moment-proptypes';
import scrollToComponent from 'react-scroll-to-component';
import images from '~/utils/ImageStore';
import PasswordField from '../../shared/PasswordField/PasswordField';
import DobPicker from '../../shared/DobPicker/DobPicker';
import Consents from '../AccountInformation/Consents/Consents';
import EnrollmentWizardNavigation from '../EnrollmentWizardNavigation';
import GuardianInfo from '../AccountInformation/GuardianInfo/GuardianInfo';
import withTranslate from '../../shared/withTranslate/withTranslate';
import { passwordValid, emailActivationfieldValidators, consentsValid } from
  '../AccountInformation/validations/accountInformation';
import './style.sass';

const formFields = [
  'password',
  'consents',
  'dateOfBirth',
  'gaurdianInfo',
];

class NovoEmailActivation extends React.Component {
  constructor() {
    super();
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onEmailActivate = this.onEmailActivate.bind(this);
  }

  componentDidMount() {
    const { tempToken, professional } = queryString.parse(this.props.location.search);
    this.onChangeValue(tempToken, 'tempToken');
    this.onChangeValue(professional === 'true', 'professional');
  }

  onSubmit(e) {
    e.preventDefault();
    this.onEmailActivate(e);
  }

  onEmailActivate(e) {
    if (this.props.isValid || (e.shiftKey && (e.altKey || e.ctrlKey))) {
      this.props.onEmailActivate();
    } else {
      this.touchFields();
      formFields.some((field) => {
        const ref = this[field.concat('Ref')];
        return !emailActivationfieldValidators[field](this.props[field], this.props, ref) &&
          scrollToComponent(ref, { align: 'top', offset: -8, duration: 500 });
      });
    }
  }

  onChangeValue(value, attribute) {
    this.props.onUpdateAttribute({ value, attribute });
  }

  touchFields() {
    formFields.forEach((field) => {
      const ref = this[field.concat('Ref')];
      if (ref && ref.touch) { ref.touch(); }
    });
  }

  render() {
    const {
      dateOfBirth, majorityAge, t, registerError,
    } = this.props;

    return (
      <div className="NovoEmailActivation">
        <img className="NovoEmailActivation-headerImage" alt="" src={images.novoActivation} />
        <div className="NovoEmailActivation-header">
          {t('activateYourAccount')}
          {!this.props.professional &&
            <div className="NovoEmailActivation-header-sub">
              {t('activateDetail')}
            </div>
          }
        </div>
        <div className="NovoEmailActivation-header-small">
          {t('activateAccountTitle')}
        </div>
        <form className="NovoEmailActivation-form" onSubmit={this.onSubmit}>
          <PasswordField
            isValid={passwordValid}
            onChange={(v) => { this.onChangeValue(v, 'password'); }}
            value={this.props.password}
            ref={(el) => { this.passwordRef = el; }}
            onKeyPress={() => {}}
            required
          />
          {!this.props.professional &&
            <DobPicker
              label={t('birthDate')}
              language={this.props.language}
              onDateChange={(v) => { this.onChangeValue(v, 'dateOfBirth'); }}
              value={this.props.dateOfBirth}
              ref={(el) => { this.dateOfBirthRef = el; }}
            />
          }
          <GuardianInfo
            label={this.props.t('birthDate')}
            dateOfBirth={this.props.dateOfBirth}
            majorityAge={this.props.majorityAge}
            onChange={(v) => { this.onChangeValue(v, 'guardianInfo'); }}
            values={this.props.guardianInfo}
            ref={(el) => { this.gaurdianInfoRef = el; }}
            immediateGuardianConsent={false}
          />
          {!this.props.professional &&
          <Consents
            label={t('termsAndConditions')}
            onChange={(v) => {
              this.onChangeValue(v, 'consents');
              this.forceUpdate();
            }}
            isValid={consentsValid}
            values={this.props.consents}
            ref={(el) => { this.consentsRef = el; }}
            dateOfBirth={dateOfBirth}
            majorityAge={majorityAge}
            immediateConsent={false}
          />
          }
          {this.props.registerStatus === 'failed' &&
            <div className="AccountInformation-form-error">
              {(registerError && registerError.date_of_birth &&
                registerError.date_of_birth[0] === 'invalid_date_of_birth_research') ?
                t('emailActivateErrorResearch') : t('emailActivateError')}
            </div>
          }
          <EnrollmentWizardNavigation
            canContinue={this.props.isValid}
            onContinue={this.onEmailActivate}
            primaryLabel={t('activate')}
            showContinue
          />
        </form>
      </div>
    );
  }
}

NovoEmailActivation.propTypes = {
  consents: PropTypes.object,
  dateOfBirth: momentPropTypes.momentObj,
  majorityAge: PropTypes.number,
  language: PropTypes.string,
  location: PropTypes.object,
  onEmailActivate: PropTypes.func.isRequired,
  onUpdateAttribute: PropTypes.func.isRequired,
  password: PropTypes.string,
  isValid: PropTypes.bool,
  registerStatus: PropTypes.string,
  registerError: PropTypes.object,
  professional: PropTypes.bool,
  guardianInfo: PropTypes.object,
  t: PropTypes.func.isRequired,
};

NovoEmailActivation.defaultProps = {
  consents: undefined,
  location: undefined,
  registerStatus: undefined,
  registerError: undefined,
  dateOfBirth: undefined,
  majorityAge: undefined,
  isValid: false,
  language: 'en',
  password: undefined,
  professional: false,
  guardianInfo: undefined,
};

export default withTranslate(NovoEmailActivation);
