import android from '~/assets/images/android.svg';
import androidSelected from '~/assets/images/android-selected.svg';
import arrowDown from '~/assets/images/arrow-down.svg';
import flags from '~/assets/images/flags.png';
import blueCheck from '~/assets/images/blue-check.svg';
import darkX from '~/assets/images/dark-x.svg';
import downloadPC from '~/assets/images/computer-illustration.svg';
import downloadMobile from '~/assets/images/mobile-phones-illustration.svg';
import ios from '~/assets/images/ios.svg';
import iosSelected from '~/assets/images/ios-selected.svg';
import glookoFavIcon from '~/assets/images/favicon.ico';
import glookoLogo from '~/assets/images/glooko-logo.svg';
import insuletLogo from '~/assets/images/partner-op5.svg';
import none from '~/assets/images/none.svg';
import noneSelected from '~/assets/images/none-selected.svg';
import preloader from '~/assets/images/preloader.gif';
import search from '~/assets/images/search.svg';
import showPasswordNotSelected from
  '~/assets/images/show-password-not-selected.svg';
import showPasswordSelected from
  '~/assets/images/show-password-selected.svg';
import hidePasswordSelected from
  '~/assets/images/hide-password-selected.svg';
import hidePasswordNotSelected from
  '~/assets/images/hide-password-not-selected.svg';
import mobile from '~/assets/images/mobile.svg';
import moreInfo from '~/assets/images/more-info.svg';
import meter from '~/assets/images/meter.svg';
import uploader from '~/assets/images/uploader.svg';
import shipping from '~/assets/images/shipping.jpg';
import activation from '~/assets/images/activation.png';
import usbC from '~/assets/images/usb-c.jpg';
import usbMicro from '~/assets/images/usb-micro.jpg';
import novoActivation from '~/assets/images/novo-activation.png';
import textSent from '~/assets/images/text-sent.svg';
import glookoWhiteLogo from '~/assets/images/glooko-logo-white.svg';

export default {
  android,
  activation,
  androidSelected,
  arrowDown,
  flags,
  blueCheck,
  darkX,
  downloadPC,
  downloadMobile,
  ios,
  iosSelected,
  glookoFavIcon,
  glookoLogo,
  insuletLogo,
  meter,
  mobile,
  moreInfo,
  none,
  noneSelected,
  preloader,
  search,
  shipping,
  showPasswordNotSelected,
  showPasswordSelected,
  hidePasswordNotSelected,
  hidePasswordSelected,
  uploader,
  usbC,
  usbMicro,
  novoActivation,
  textSent,
  glookoWhiteLogo,
};
